import React, { useState, useEffect, useContext, useCallback } from "react";
import Test from "./components/Test";
import Footer from "../../../../components/footer/Footer";
import "../../../../css/index.css";
//import fontsizeFunctions from "../../../../functions/fontsizeFunctions";
import draggableFunctions from "../../../../functions/draggableFunctions";
import { AssessmentContext } from "../../../../context/AssessmentProvider";
import { useScoreNLP } from "../../../../hooks/useScoreNLP";
import { checkEnglishInput } from "../../../../functions/genericFunctions";

const LSA = (props) => {
  const testData = props.testData;
  const [state, setState] = useState({
    LO: testData._id,
    actID: testData.testitems[props.counter]._id,
    modality: testData.testitems[props.counter].modality,
    template: testData.testitems[props.counter].template,
    skill: testData.proficiencyLevel,

    mainAudio: testData.testitems[props.counter].mainAudio,
    modifiedAudio: testData.testitems[props.counter].modifiedAudio,
    questionText: testData.testitems[props.counter].questionTexts,
    question: testData.testitems[props.counter].questions,
    lang: testData.language._id,
    userAnswerMain: [],
    userAnswerMod: [],
    checkAnswerMain: [],
    checkAnswerMod: [],
    scoredAnswerMain: [],
    scoredAnswerMod: [],
    matchAnswerMain: [],
    matchAnswerMod: [],
    modifiedAudioPlayed: false,
    correctAnswer: testData.testitems[props.counter].answerFields,
    answerField: testData.testitems[props.counter].answerFields,
    audioFile: "",
    audioKey: Math.random(),
    anchorKey: new Date(),

    itemMatch0: false,
    itemMatch1: false,
  });

  // const [scoredAnswerMain, setScoredAnswer] = useState([]);
  // const [scoredAnswerMod, setScoredAnswerMod] = useState([]);
  const [assessmentState] = useContext(AssessmentContext);
  const [runScoreNLP, setRunScoreNLP] = useState(false);
  const [eventNLP, setEventNLP] = useState(false);
  const [storeNLPScores, setStoreNLPScores] = useState([]);
  const [finalChecking, setFinalChecking] = useState(false);
  const [showNonENTextError, setShowNonENTextError] = useState([]);

  state.userAnswerMain.length =
    testData.testitems[props.counter].answerFields.length;
  state.userAnswerMod.length =
    testData.testitems[props.counter].answerFields.length;

  state.checkAnswerMain.length =
    testData.testitems[props.counter].answerFields.length;
  state.checkAnswerMod.length =
    testData.testitems[props.counter].answerFields.length;

  state.matchAnswerMain.length =
    testData.testitems[props.counter].answerFields.length;
  state.matchAnswerMod.length =
    testData.testitems[props.counter].answerFields.length;

  useEffect(() => {
    //fontSizeFunctions();
    draggableFunctions();
    return () => {};
  }, []);

  useEffect(() => {
    //disable next/submit until previous api call is complete; prevents overrunning api calls to change same data
    if (props.buttonWaiting) {
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      apiWaitButtons.forEach((item, index) => {
        item.classList.add("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title =
            "Please answer the questions above before proceeding. You will also need to wait for the system to record your previous answer before proceeding.";
        } else {
          titleChanger.title =
            "Please wait for your assessment to save your previous answer before proceeding.";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivUnanswered");
      titleChanger.classList.add("nextSubmitParentDivWaiting");
    } else {
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      apiWaitButtons.forEach((item, index) => {
        item.classList.remove("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title =
            "Please attempt to answers all questions before proceeding.  Note: If you feel your assessment is stuck and you can not proceed hold SHIFT and left clicking this button to unlock it.  Next, click the button normally to proceed without answering the question.";
        } else {
          titleChanger.title = "Click to submit your answer(s).";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivWaiting");
      titleChanger.classList.add("nextSubmitParentDivUnanswered");
    }
  }, [props.buttonWaiting]);

  const judgeAnswers = useCallback(
    (index, value) => {
      const itemId = index;
      const userAns =
        typeof value === "string" ? value.replace(/,/g, "") : value;
      let matching;
      let itemScore = 0;
      let countAnswer = 0;
      let matchAnswer = [];
      let checkAnswer = false;

      const passingScore = state.correctAnswer[itemId].passingScore;
      if (userAns !== undefined) {
        if (passingScore == "100") {
          const answerKeywordLen =
            state.correctAnswer[itemId].answerKeywords.length;

          for (let j = 0; j < answerKeywordLen; j++) {
            const patternLen =
              state.correctAnswer[itemId].answerKeywords[j].matchingPatterns
                .length;

            for (let k = 0; k < patternLen; k++) {
              const crrtAns =
                state.correctAnswer[itemId].answerKeywords[j].matchingPatterns[
                  k
                ] + ".*";

              matching = userAns.toLowerCase().match(crrtAns.toLowerCase());
              if (matching !== null) {
                countAnswer++;
                matchAnswer.push(crrtAns.toLowerCase());
                break;
              }
            }
          }

          if (countAnswer == answerKeywordLen) {
            checkAnswer = true;
          }
          return { checkAnswer, matchAnswer };
        } else {
          const answerKeywordLen =
            state.correctAnswer[itemId].answerKeywords.length;

          for (let j = 0; j < answerKeywordLen; j++) {
            let individualScore =
              state.correctAnswer[itemId].answerKeywords[j].individualScore;

            const patternLen =
              state.correctAnswer[itemId].answerKeywords[j].matchingPatterns
                .length;

            for (let k = 0; k < patternLen; k++) {
              const crrtAns =
                state.correctAnswer[itemId].answerKeywords[j].matchingPatterns[
                  k
                ] + ".*";

              matching = userAns.toLowerCase().match(crrtAns.toLowerCase());
              if (matching !== null) {
                itemScore = itemScore + individualScore;
                matchAnswer.push(crrtAns.toLowerCase());
                break;
              }
            }
          }
          if (itemScore >= passingScore) {
            checkAnswer = true;
          }
          return { checkAnswer, matchAnswer };
        }
      }
    },
    [state]
  );

  const handleAnswerMain = useCallback(
    (event) => {
      const index = event.currentTarget.id.substr(-3, 1);
      const id = event.currentTarget.id;
      const value = event.currentTarget.value;

      let new_state = Object.assign({}, state);
      let a = new_state.userAnswerMain;
      let b = new_state.checkAnswerMain;
      let c = new_state.matchAnswerMain;
      let d = new_state.matchAnswerMod;
      let z = new_state.scoredAnswerMain;

      let cnt = 0;
      let finalCheck = null;

      const answerCheck = judgeAnswers(index, value).checkAnswer;
      const answerMatch = judgeAnswers(index, value).matchAnswer;

      /*test english input*/
      /* set up a dynamic array in state to track which elements have invalid characters */
      if (state.correctAnswer[index].isKeywordInEnglish) {
        if (!checkEnglishInput(value)) {
          let showNonENTextErrorTemp = showNonENTextError;
          if (
            !showNonENTextErrorTemp.find(
              (thisEl) => thisEl === id + "_" + index
            ) ||
            !showNonENTextErrorTemp.find((thisEl) => thisEl === id)
          ) {
            /*if invalid & doesnt yet exist; add it to error array */
            showNonENTextErrorTemp.push(id + "_" + index);
            showNonENTextErrorTemp.push(id);
            setShowNonENTextError(showNonENTextErrorTemp);
          } else {
            //do nothing
          }
        } else {
          let showNonENTextErrorTemp = showNonENTextError;
          if (
            showNonENTextErrorTemp.find((thisEl) => thisEl === id + "_" + index)
          ) {
            /*if valid & exist; remove it from error array */
            let remIndex = (subEl) => subEl === id + "_" + index;
            let thisIndex = showNonENTextErrorTemp.findIndex(remIndex);
            showNonENTextErrorTemp.splice(thisIndex, 1);
          }
          if (showNonENTextErrorTemp.find((thisEl) => thisEl === id)) {
            /*if valid & exist; remove it from error array */
            let remIndex = (subEl) => subEl === id;
            let thisIndex = showNonENTextErrorTemp.findIndex(remIndex);
            showNonENTextErrorTemp.splice(thisIndex, 1);
          }
          setShowNonENTextError(showNonENTextErrorTemp);
        }
      }

      a[index] = value;
      b[index] = answerCheck;
      c[index] = answerMatch;
      z[index] = {
        uiControlID: id,
        testlet: testData._id,
        testitem: state.actID,
        answerField: state.answerField[index]._id,
        keywordMatches: answerMatch,
        userAnswer: value,
        isPassed: answerCheck,
        isModifiedAudioPlayed: false,
        isTranscriptShown: false,
      };

      d[index] = {
        uiControlID: id.substring(0, id.length - 1) + 1,
        testlet: testData._id,
        testitem: state.actID,
        answerField: state.answerField[index]._id,
        keywordMatches: [],
        userAnswer: null,
        isPassed: false,
        isModifiedAudioPlayed: false,
        isTranscriptShown: false,
      };

      setState({
        ...state,
        userAnswerMain: a,
        checkAnswerMain: b,
        matchAnswerMain: c,
        scoredAnswerMod: d,
        scoredAnswerMain: z,
      });

      const ansLen = state.checkAnswerMain.length;
      for (let i = 0; i < ansLen; i++) {
        if (state.checkAnswerMain[i] === true) {
          cnt++;
        }
      }
      if (cnt === state.correctAnswer.length) {
        finalCheck = true;
      } else {
        finalCheck = false;
      }

      const len = state.userAnswerMain.length;
      let num = 0;
      for (let i = 0; i < len; i++) {
        if (state.userAnswerMain[i] !== undefined) {
          num++;
          if (state.userAnswerMain[i].length === 0) {
            num--;
          }
        }
      }

      if (num === len) {
        document.querySelector(".nextBtn").classList.remove("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.remove("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title =
            "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Click to submit your answers.";
        }
      } else {
        document.querySelector(".nextBtn").classList.add("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.add("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title =
            "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title =
            "Please attempt to re-answer at least one incorrect question before proceeding.";
        }
      }
    },
    [state, judgeAnswers, showNonENTextError]
  );

  const handleAnswerMod = useCallback(
    (event) => {
      const index = event.currentTarget.id.substr(-3, 1);
      const id = event.currentTarget.id;
      const value = event.currentTarget.value;

      //setState({...state, modifiedAudioPlayed: true, itemMatch0: false, itemMatch1: false });

      let new_state = Object.assign({}, state);
      let a = new_state.userAnswerMod;
      let b = new_state.checkAnswerMod;
      let c = new_state.matchAnswerMod;
      let z = new_state.scoredAnswerMod;

      let cnt = 0;
      let finalCheck = null;

      const answerCheck = judgeAnswers(index, value).checkAnswer;
      const answerGet = judgeAnswers(index, value).matchAnswer;

      /*test english input*/
      /* set up a dynamic array in state to track which elements have invalid characters */
      if (state.correctAnswer[index].isKeywordInEnglish) {
        if (!checkEnglishInput(value)) {
          let showNonENTextErrorTemp = showNonENTextError;
          if (
            !showNonENTextErrorTemp.find(
              (thisEl) => thisEl === id + "_" + index
            ) ||
            !showNonENTextErrorTemp.find((thisEl) => thisEl === id)
          ) {
            /*if invalid & doesnt yet exist; add it to error array */
            showNonENTextErrorTemp.push(id + "_" + index);
            showNonENTextErrorTemp.push(id);
            setShowNonENTextError(showNonENTextErrorTemp);
          } else {
            //do nothing
          }
        } else {
          let showNonENTextErrorTemp = showNonENTextError;
          if (
            showNonENTextErrorTemp.find((thisEl) => thisEl === id + "_" + index)
          ) {
            /*if valid & exist; remove it from error array */
            let remIndex = (subEl) => subEl === id + "_" + index;
            let thisIndex = showNonENTextErrorTemp.findIndex(remIndex);
            showNonENTextErrorTemp.splice(thisIndex, 1);
          }
          if (showNonENTextErrorTemp.find((thisEl) => thisEl === id)) {
            /*if valid & exist; remove it from error array */
            let remIndex = (subEl) => subEl === id;
            let thisIndex = showNonENTextErrorTemp.findIndex(remIndex);
            showNonENTextErrorTemp.splice(thisIndex, 1);
          }
          setShowNonENTextError(showNonENTextErrorTemp);
        }
      }

      a[index] = value;
      b[index] = answerCheck;
      c[index] = answerGet;
      z[index] = {
        uiControlID: id,
        testlet: testData._id,
        testitem: state.actID,
        answerField: state.answerField[index]._id,
        keywordMatches: answerGet,
        userAnswer: value,
        isPassed: answerCheck,
        isModifiedAudioPlayed: state.modifiedAudioPlayed,
        isTranscriptShown: false,
      };
      setState({
        ...state,
        modifiedAudioPlayed: true,
        itemMatch0: false,
        itemMatch1: false,
        userAnswerMod: a,
        checkAnswerMod: b,
        matchAnswerMod: c,
        scoredAnswerMod: z,
      });

      const ansLen = state.checkAnswerMod.length;
      for (let i = 0; i < ansLen; i++) {
        if (state.checkAnswerMod[i] === true) {
          cnt++;
        }
      }
      if (cnt === state.correctAnswer.length) {
        finalCheck = true;
      } else {
        finalCheck = false;
      }

      const len = state.userAnswerMod.length;
      let num = 0;
      for (let i = 0; i < len; i++) {
        if (state.userAnswerMod[i] !== undefined) {
          num++;
          if (state.userAnswerMod[i].length === 0) {
            num--;
          }
        }
      }
      if (num === len) {
        document.querySelector(".nextBtn").classList.remove("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.remove("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title =
            "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Click to submit your answers.";
        }
      } else {
        document.querySelector(".nextBtn").classList.add("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.add("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title =
            "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title =
            "Please attempt to re-answer at least one incorrect question before proceeding.";
        }
      }
    },
    [state, judgeAnswers]
  );

  const combinedAnswers = useCallback(
    (btnName) => {
      let mainAns = state.scoredAnswerMain;
      const modAns = state.scoredAnswerMod;

      if (mainAns.length === 0) {
        const newItem = {
          uiControlID: state.actID + "_0_0",
          testlet: state.LO,
          testitem: state.actID,
          answerField: state.actID + "_0",
          keywordMatches: [],
          userAnswer: null,
          isPassed: false,
          isModifiedAudioPlayed: false,
          isTranscriptShown: false,
        };

        mainAns.splice(0, 0, newItem);
      }

      let combinedAns = null;
      if (!state.modifiedAudioPlayed) {
        combinedAns = mainAns;
      } else {
        combinedAns = mainAns.concat(modAns);
      }

      props.parentCallback(JSON.stringify(combinedAns), btnName);
    },
    [state.scoredAnswerMain, state.scoredAnswerMod, state]
  );

  const handleEval = useCallback((name) => {
    const btnName = name;
    combinedAnswers(btnName);
  });

  // ** Issue #163 - disable NLP calls until post-launch period
  // //** customHook & effects to score from NLP API */
  // const [nlpScoreData, nlpScoreRunning, nlpScoreError] = useScoreNLP(
  //   eventNLP,
  //   "LSA",
  //   assessmentState.assessmentSessionId,
  //   state.correctAnswer,
  //   runScoreNLP
  // );
  // //only execute once
  // useEffect(() => {
  //   if (runScoreNLP) {
  //     setRunScoreNLP(false); //resets to false after one run
  //   }
  // }, [runScoreNLP]);
  // //initialization call from react component
  // const checkNLP = useCallback((event) => {
  //   setEventNLP(event);
  //   setRunScoreNLP(true);
  // }, []);
  // //store the async results in a static object we can reference at the end of the testlet
  // useEffect(() => {
  //   if (nlpScoreData !== null) {
  //     let tempArr = storeNLPScores;
  //     if (nlpScoreData.testletId !== null && nlpScoreData.nlpScore !== null) {
  //       tempArr[nlpScoreData.testletId] = nlpScoreData.nlpScore;
  //       //setStoreNLPScores(tempArr);
  //     }
  //     //console.log(tempArr);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [nlpScoreData]);
  // useEffect(() => {
  //   if (nlpScoreError !== null) {
  //     console.log("NLP error: " + nlpScoreError);
  //   }
  // }, [nlpScoreError]);

  const renderTest = () => {
    return (
      <>
        <Test
          LO={state.LO}
          actID={state.actID}
          lang={state.lang}
          audKey={state.audioKey}
          anchorKey={state.anchorKey}
          mainAudio={state.mainAudio}
          modifiedAudio={state.modifiedAudio}
          questionText={state.questionText}
          question={state.question}
          onAnswerMain={handleAnswerMain}
          onAnswerMod={handleAnswerMod}
          navLen={props.navLen}
          navCrrtItem={props.navCrrtItem}
          onClickNextBtn={props.clickNextBtn}
          onSubmitBtn={props.submitBtn}
          onEvaluation={handleEval}
          // ** Issue #163 - disable NLP calls until post-launch period
          //onLoseFocusCheckNLP={checkNLP}
          invalidEnInput={showNonENTextError}
        />
        <Footer
          actID={state.actID}
          mod={state.modality}
          template={state.template}
          skill={state.skill}
        />
      </>
    );
  };

  return <div>{renderTest()}</div>;
};

export default LSA;
