export default function Next(props) {
  const totLen = parseInt(props.navLen);
  const crrtItem = parseInt(props.navCrrtItem);

  const clickNextBtn = props.onClickNextBtn;
  const clickSubmitBtn = props.onSubmit;
  const evaluation = props.onEvaluation;

  const nextCombo = (e) => {
    const btnName = e.currentTarget.name;
    clickNextBtn();
    evaluation(btnName);
  };

  const submitCombo = (e) => {
    const btnName = e.currentTarget.name;
    evaluation(btnName);
    clickSubmitBtn();
  };

  const nxt = "Next";
  const sub = "Submit";
  const next = (
    <button className="outlined nextBtn rclc_next disabled" name="next" onClick={nextCombo}>
      {nxt}
    </button>
  );
  const submit = (
    <button className="outlined nextBtn rclc_next disabled" name="submit" onClick={submitCombo}>
      {sub}
    </button>
  );
  const checkForBypassToggle = (e) => {
    let nxtBtnDisableEle = document.querySelector("button.nextBtn.disabled");

    if (e.shiftKey && typeof nxtBtnDisableEle !== "undefined") {
      console.log("Bypassing logic for stuck question.");
      if (typeof nxtBtnDisableEle !== "undefined" && nxtBtnDisableEle !== null) {
        nxtBtnDisableEle.classList.remove("disabled");
        setTimeout(()=>{nxtBtnDisableEle.classList.remove("waitingForAPI");
        },2000);
      }
    }
  };

  return (
    <div className="nextSubmitParentDiv" title="" onClick={(e) => checkForBypassToggle(e)}>
      {totLen === crrtItem ? submit : next}
    </div>
  );
}
