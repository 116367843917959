import React, { useRef } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import dompurify from "dompurify";
import { IoPlay } from "react-icons/io5";
import { IoPause } from "react-icons/io5";
import { GiSpeaker } from "react-icons/gi";
import { IoIosRewind } from "react-icons/io";
import { IoMdFastforward } from "react-icons/io";

export default function Audio(props) {
  const sanitizer = dompurify.sanitize;
  const LO = props.LO;
  const lang = props.lang;
  const mainAudio = props.mainAudio;
  const modifiedAudio = props.modifiedAudio;
  const itemInContextAudio = props.itemInContextAudio;
  const main = "audio/" + lang + "/" + LO + "/" + mainAudio;
  const mod = "audio/" + lang + "/" + LO + "/" + modifiedAudio;
  const context = "audio/" + lang + "/" + LO + "/" + itemInContextAudio;
  const questionText = props.questionText;
  const audioPlayer = useRef(null);

  return (
    <div>
      <h2>Listen to the passage:</h2>
      <AudioPlayer
        id="ODAPlayer2"
        autoPlay={false}
        autoPlayAfterSrcChange={false}
        header={
          <div className="rhap_textCenter">
            Main Passage {props.toggleState ? "(Modified)" : ""}
            <span className="modifiedFlag" />
          </div>
        }
        footer={null}
        customAdditionalControls={[]}
        ref={audioPlayer.main}
        src={props.toggleState ? mod : main}
        customIcons={{
          play: <IoPlay />,
          pause: <IoPause />,
          rewind: <IoIosRewind />,
          forward: <IoMdFastforward />,
          volume: <GiSpeaker />
        }}
        // onPlay={(e) => console.log("onPlay")}
      />

      <h3>
        {props.itemInContextAudio !== null && (
          <div className="audioQ">
            <AudioPlayer
              autoPlay={false}
              autoPlayAfterSrcChange={false}
              header={"Item In Context"}
              footer={null}
              hasDefaultKeyBindings={false}
              customAdditionalControls={[]}
              customVolumeControls={[]}
              ref={audioPlayer.inline0}
              src={context}
              showJumpControls={false}
              showDownloadProgress={false}
              showFilledProgress={false}
              customIcons={{
                play: <IoPlay />,
                pause: <IoPause />,
                rewind: <IoIosRewind />,
                forward: <IoMdFastforward />,
                volume: <GiSpeaker />
              }}
              // onPlay={(e) => console.log("onPlay")}
            />
          </div>
        )}
        <div className="audioQ">
          <span
            key={questionText[0]}
            dangerouslySetInnerHTML={{
              __html: sanitizer(questionText[0])
            }}
          />
          {(questionText.length === 2 || questionText.length === 3) && (
            <>
              <AudioPlayer
                autoPlay={false}
                autoPlayAfterSrcChange={false}
                header={null}
                footer={null}
                hasDefaultKeyBindings={false}
                customAdditionalControls={[]}
                customVolumeControls={[]}
                ref={audioPlayer.inline0}
                src={"audio/" + lang + "/" + LO + "/" + questionText[1]}
                showJumpControls={false}
                showDownloadProgress={false}
                showFilledProgress={false}
                customIcons={{
                  play: <IoPlay />,
                  pause: <IoPause />,
                  rewind: <IoIosRewind />,
                  forward: <IoMdFastforward />,
                  volume: <GiSpeaker />
                }}
                // onPlay={(e) => console.log("onPlay")}
              />
              {questionText.length === 3 && (
                <span
                  key={questionText[2]}
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(questionText[2])
                  }}
                />
              )}
            </>
          )}
        </div>
      </h3>
    </div>
  );
}
