import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import Test from "./components/Test";
import Footer from "../../../../components/footer/Footer";
import "../../../../css/index.css";
//import fontsizeFunctions from "../../../../functions/fontsizeFunctions";
import draggableFunctions from "../../../../functions/draggableFunctions";
import { AssessmentContext } from "../../../../context/AssessmentProvider";
import { useScoreNLP } from "../../../../hooks/useScoreNLP";
import { checkEnglishInput } from "../../../../functions/genericFunctions";

const LCM = (props) => {
  const testData = props.testData;
  const numRef = useRef(0);
  const numRefMod = useRef(0);
  const [state, setState] = useState({
    LO: testData._id,
    actID: testData.testitems[props.counter]._id,
    modality: testData.testitems[props.counter].modality,
    template: testData.testitems[props.counter].template,
    skill: testData.proficiencyLevel,

    mainAudio: testData.testitems[props.counter].mainAudio,
    questionText: testData.testitems[props.counter].questionTexts,
    question: testData.testitems[props.counter].questions,
    lang: testData.language._id,
    dir: testData.language.isRightToLeft,
    correctAnswer: testData.testitems[props.counter].answerFields,
    answerField: testData.testitems[props.counter].answerFields,
    checkAnswer: [],
    userAnswer: [],
    matchAnswer: [],
    scoredAnswer: [],
    checkAnswerMod: [],
    userAnswerMod: [],
    matchAnswerMod: [],
    scoredAnswerMod: [],
    audioKey: Math.random(),
    anchorKey: new Date(),
    mainNum: 0,
    modNum: 0,
    modMode: false,
    transcript: []
  });
  // const [scoredAnswer, setScoredAnswer] = useState([]);
  const [scoredAnswerMod, setScoredAnswerMod] = useState([]);
  const [assessmentState] = useContext(AssessmentContext);
  const [runScoreNLP, setRunScoreNLP] = useState(false);
  const [eventNLP, setEventNLP] = useState(false);
  const [storeNLPScores, setStoreNLPScores] = useState([]);
  const [finalChecking, setFinalChecking] = useState(false);
  const [showNonENTextError, setShowNonENTextError] = useState([]);
  state.userAnswer.length = testData.testitems[props.counter].answerFields.length;
  state.userAnswerMod.length = testData.testitems[props.counter].answerFields.length;
  state.checkAnswer.length = testData.testitems[props.counter].answerFields.length;
  state.checkAnswerMod.length = testData.testitems[props.counter].answerFields.length;
  state.matchAnswer.length = testData.testitems[props.counter].answerFields.length;
  state.matchAnswerMod.length = testData.testitems[props.counter].answerFields.length;
  state.transcript.length = testData.testitems[props.counter].answerFields.length;

  // alert(state.LO);
  // alert(state.actID);

  useEffect(() => {
    //fontSizeFunctions();
    draggableFunctions();

    return () => {};
  }, []);

  useEffect(() => {
    //disable next/submit until previous api call is complete; prevents overrunning api calls to change same data
    if (props.buttonWaiting) {
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      apiWaitButtons.forEach((item, index) => {
        item.classList.add("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title =
            "Please answer the questions above before proceeding. You will also need to wait for the system to record your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivUnanswered");
      titleChanger.classList.add("nextSubmitParentDivWaiting");
    } else {
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      apiWaitButtons.forEach((item, index) => {
        item.classList.remove("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title =
            "Please attempt to answers all questions before proceeding.  Note: If you feel your assessment is stuck and you can not proceed hold SHIFT and left clicking this button to unlock it.  Next, click the button normally to proceed without answering the question.";
        } else {
          titleChanger.title = "Click to submit your answer(s).";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivWaiting");
      titleChanger.classList.add("nextSubmitParentDivUnanswered");
    }
  }, [props.buttonWaiting]);

  const judgeAnswers = useCallback(
    (index, value) => {
      let matching;
      let countAnswer = 0;
      let checkAnswer = false;
      let matchAnswer = [];
      let itemScore = 0;

      const passingScore = JSON.stringify(state.correctAnswer[index].passingScore);
      if (passingScore == "100") {
        const answerKeywordLen = state.correctAnswer[index].answerKeywords.length;
        for (let i = 0; i < answerKeywordLen; i++) {
          const patternLen = state.correctAnswer[index].answerKeywords[i].matchingPatterns.length;
          for (let j = 0; j < patternLen; j++) {
            const crrtAns = state.correctAnswer[index].answerKeywords[i].matchingPatterns[j] + ".*";
            matching = value.toLowerCase().match(crrtAns.toLowerCase());
            if (matching !== null) {
              countAnswer = countAnswer + 1;
              matchAnswer.push(crrtAns.toLowerCase());
              break;
            }
          }
        }
        if (countAnswer == answerKeywordLen) {
          checkAnswer = true;
        }

        return { checkAnswer, matchAnswer };
      } else {
        const answerKeywordLen = state.correctAnswer[index].answerKeywords.length;
        for (let i = 0; i < answerKeywordLen; i++) {
          let individualScore = state.correctAnswer[index].answerKeywords[i].individualScore;
          const patternLen = state.correctAnswer[index].answerKeywords[i].matchingPatterns.length;
          for (let j = 0; j < patternLen; j++) {
            const crrtAns = state.correctAnswer[index].answerKeywords[i].matchingPatterns[j] + ".*";
            matching = value.toLowerCase().match(crrtAns.toLowerCase());
            if (matching !== null) {
              itemScore = itemScore + individualScore;
              matchAnswer.push(crrtAns.toLowerCase());
              break;
            }
          }
        }
        if (itemScore >= passingScore) {
          checkAnswer = true;
        }
        return { checkAnswer, matchAnswer };
      }
    },
    [state]
  );

  const handleClick = useCallback(
    (event, specificId = null, specificValue = null) => {
      const dataIndex = 0;
      let id, index, value;

      if (specificId === null || specificValue === null) {
        id = event.currentTarget.id;
        index = event.currentTarget.id.substr(-3, 1);
        value = event.currentTarget.value;
      } else {
        id = specificId;
        index = id.substr(-3, 1);
        value = specificValue !== "" ? specificValue : "N/A";
      }

      let new_state = Object.assign({}, state);
      let a = new_state.userAnswer;
      let b = new_state.checkAnswer;
      let c = new_state.matchAnswer;
      // let d = new_state.matchAnswerMod;
      let z = new_state.scoredAnswer;

      const answerCheck = judgeAnswers(index, value).checkAnswer;
      const answerMatch = judgeAnswers(index, value).matchAnswer;

      /*test english input*/
      /* set up a dynamic array in state to track which elements have invalid characters */
      if (state.correctAnswer[index].isKeywordInEnglish) {
        if (!checkEnglishInput(value)) {
          let showNonENTextErrorTemp = showNonENTextError;
          if (
            !showNonENTextErrorTemp.find((thisEl) => thisEl === id + "_" + index) ||
            !showNonENTextErrorTemp.find((thisEl) => thisEl === id)
          ) {
            /*if invalid & doesnt yet exist; add it to error array */
            showNonENTextErrorTemp.push(id + "_" + index);
            showNonENTextErrorTemp.push(id);
            setShowNonENTextError(showNonENTextErrorTemp);
          } else {
            //do nothing
          }
        } else {
          let showNonENTextErrorTemp = showNonENTextError;
          if (showNonENTextErrorTemp.find((thisEl) => thisEl === id + "_" + index)) {
            /*if valid & exist; remove it from error array */
            let remIndex = (subEl) => subEl === id + "_" + index;
            let thisIndex = showNonENTextErrorTemp.findIndex(remIndex);
            showNonENTextErrorTemp.splice(thisIndex, 1);
          }
          if (showNonENTextErrorTemp.find((thisEl) => thisEl === id)) {
            /*if valid & exist; remove it from error array */
            let remIndex = (subEl) => subEl === id;
            let thisIndex = showNonENTextErrorTemp.findIndex(remIndex);
            showNonENTextErrorTemp.splice(thisIndex, 1);
          }
          setShowNonENTextError(showNonENTextErrorTemp);
        }
      }

      a[index] = value;
      b[index] = answerCheck;
      c[index] = answerMatch;
      z[index] = {
        uiControlID: id,
        testlet: testData._id,
        testitem: state.actID,
        answerField: state.answerField[index]._id,
        keywordMatches: answerMatch,
        userAnswer: value,
        isPassed: answerCheck,
        isModifiedAudioPlayed: false,
        isTranscriptShown: false
      };

      let TransToMod = [];
      if (id != null) {
        let selID = id.substring(0, id.length - 1);
        TransToMod.push(selID + "1");
      }
      document.querySelector("#" + TransToMod).value = value !== "N/A" ? value : "";

      // d[index] = {
      //   uiControlID: id.substring(0, id.length - 1) + 1,
      //   testlet: testData._id,
      //   testitem: state.actID,
      //   answerField: state.answerField[index]._id,
      //   keywordMatches: [],
      //   userAnswer: null,
      //   isPassed: false,
      //   isModifiedAudioPlayed: false,
      //   isTranscriptShown: false,
      // };

      const len = state.userAnswer.length;

      let num = 0;
      let modNum = numRefMod.current;

      for (let i = 0; i < len; i++) {
        if (state.userAnswer[i] !== undefined) {
          num++;
          if (state.userAnswer[i].length === 0) {
            num--;
          }
        }
      }
      numRef.current = num;

      setState({
        ...state,
        userAnswer: a,
        checkAnswer: b,
        matchAnswer: c,
        // scoredAnswerMod: d,
        scoredAnswer: z
        // mainNum: num
      });

      if (len === num + modNum || len === num) {
        document.querySelector(".nextBtn").classList.remove("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Click to submit your answers.";
        }
        titleChanger.classList.remove("nextSubmitParentDivUnanswered");
      } else {
        document.querySelector(".nextBtn").classList.add("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title =
            "Please attempt to re-answer at least one incorrect question before proceeding. You will also need to wait for the system to record your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please attempt to answer all questions before proceeding.";
        }
        titleChanger.classList.add("nextSubmitParentDivUnanswered");
      }
    },
    [state, judgeAnswers, showNonENTextError]
  );

  const handleClickMod = useCallback(
    (event, specificId = null, specificValue = null) => {
      const dataIndex = 1;
      let id, index, value;
      if (specificId === null) {
        id = event.currentTarget.id;
        index = event.currentTarget.id.substr(-3, 1);
        value = event.currentTarget.value;
      } else {
        id = specificId;
        index = id.substr(-3, 1);
        value = specificValue;
      }
      const transcriptBool = (state.transcript[index] = true);

      const answerCheck = judgeAnswers(index, value).checkAnswer;
      const answerMatch = judgeAnswers(index, value).matchAnswer;

      /*test english input*/
      /* set up a dynamic array in state to track which elements have invalid characters */
      if (state.correctAnswer[index].isKeywordInEnglish) {
        if (!checkEnglishInput(value)) {
          let showNonENTextErrorTemp = showNonENTextError;
          if (
            !showNonENTextErrorTemp.find((thisEl) => thisEl === id + "_" + index) ||
            !showNonENTextErrorTemp.find((thisEl) => thisEl === id)
          ) {
            /*if invalid & doesnt yet exist; add it to error array */
            showNonENTextErrorTemp.push(id + "_" + index);
            showNonENTextErrorTemp.push(id);
            setShowNonENTextError(showNonENTextErrorTemp);
          } else {
            //do nothing
          }
        } else {
          let showNonENTextErrorTemp = showNonENTextError;
          if (showNonENTextErrorTemp.find((thisEl) => thisEl === id + "_" + index)) {
            /*if valid & exist; remove it from error array */
            let remIndex = (subEl) => subEl === id + "_" + index;
            let thisIndex = showNonENTextErrorTemp.findIndex(remIndex);
            showNonENTextErrorTemp.splice(thisIndex, 1);
          }
          if (showNonENTextErrorTemp.find((thisEl) => thisEl === id)) {
            /*if valid & exist; remove it from error array */
            let remIndex = (subEl) => subEl === id;
            let thisIndex = showNonENTextErrorTemp.findIndex(remIndex);
            showNonENTextErrorTemp.splice(thisIndex, 1);
          }
          setShowNonENTextError(showNonENTextErrorTemp);
        }
      }

      let new_state = Object.assign({}, state);
      let a = new_state.userAnswerMod;
      let b = new_state.checkAnswerMod;
      let c = new_state.matchAnswerMod;
      let d = new_state.transcript;
      let z = new_state.scoredAnswerMod;

      a[index] = value;
      b[index] = answerCheck;
      c[index] = answerMatch;
      d[index] = transcriptBool;
      z[index] = {
        uiControlID: id,
        testlet: testData._id,
        testitem: state.actID,
        answerField: state.answerField[index]._id,
        keywordMatches: answerMatch,
        userAnswer: value,
        isPassed: answerCheck,
        isModifiedAudioPlayed: false,
        isTranscriptShown: state.transcript[index]
      };

      const len = parseInt(state.question.length / 3);

      let num = numRef.current;
      if (num === undefined) {
        num = 0;
      }
      let modNum = 0;

      for (let i = 0; i < len; i++) {
        if (
          (state.userAnswer[i] === undefined || state.userAnswer[i] === "") &&
          state.userAnswerMod[i] !== undefined &&
          state.userAnswerMod[i] !== ""
        ) {
          num++;
          modNum++;
        }
      }
      numRefMod.current = modNum;

      if (num === len) {
        document.querySelector(".nextBtn").classList.remove("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.remove("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Click to submit your answers.";
        }
      } else {
        document.querySelector(".nextBtn").classList.add("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.add("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please attempt to answer all questions before proceeding.";
        }
      }

      setState({ ...state, modNum: modNum, modMode: true });
    },
    [state, judgeAnswers, showNonENTextError]
  );

  const combinedAnswers = useCallback(
    (btnName) => {
      const mainAns = state.scoredAnswer;
      const modAns = state.scoredAnswerMod;

      let combinedAns = null;
      let anyTranscript = false;
      let len = state.transcript.length;

      for (let i = 0; i < len; i++) {
        if (state.transcript[i] === true) {
          anyTranscript = true;
        }
      }
      // Adding a fake user answer (for the 1st attempt) when moving to the 2nd attempt to answer, skipping the 1st (leaving it emapty)
      for (let j = 0; j < mainAns.length; j++) {
        const newItem = {
          uiControlID: state.actID + "_" + j + "_0",
          testlet: state.LO,
          testitem: state.actID,
          answerField: state.actID + "_" + j,
          keywordMatches: [],
          userAnswer: null,
          isPassed: false,
          isModifiedAudioPlayed: false,
          isTranscriptShown: false
        };
        if (mainAns[j] === undefined) {
          mainAns.splice(j, 1, newItem);
        }
      }

      //Removing the automatically generated modified (2nd attempt) answer which is defined as null since a user didn't click on the transcript hint.
      for (let k = modAns.length - 1; k >= 0; k--) {
        if (modAns[k] === undefined) {
          modAns.splice(k, 1);
        }
      }

      if (!anyTranscript) {
        combinedAns = mainAns;
      } else {
        combinedAns = mainAns.concat(modAns);
      }
      props.parentCallback(JSON.stringify(combinedAns), btnName);
    },
    [state.scoredAnswer, state.scoredAnswerMod]
  );

  const handleEval = useCallback((name) => {
    const btnName = name;
    combinedAnswers(btnName);
  });

  // ** Issue #163 - disable NLP calls until post-launch period
  //** customHook & effects to score from NLP API */
  // const [nlpScoreData, nlpScoreRunning, nlpScoreError] = useScoreNLP(
  //   eventNLP,
  //   "LSA",
  //   assessmentState.assessmentSessionId,
  //   state.correctAnswer,
  //   runScoreNLP
  // );
  // //only execute once
  // useEffect(() => {
  //   if (runScoreNLP) {
  //     setRunScoreNLP(false); //resets to false after one run
  //   }
  // }, [runScoreNLP]);
  // //initialization call from react component
  // const checkNLP = useCallback((event) => {
  //   setEventNLP(event);
  //   setRunScoreNLP(true);
  // }, []);
  // //store the async results in a static object we can reference at the end of the testlet
  // useEffect(() => {
  //   if (nlpScoreData !== null) {
  //     let tempArr = storeNLPScores;
  //     if (nlpScoreData.testletId !== null && nlpScoreData.nlpScore !== null) {
  //       tempArr[nlpScoreData.testletId] = nlpScoreData.nlpScore;
  //       //setStoreNLPScores(tempArr);
  //     }
  //     //console.log(tempArr);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [nlpScoreData]);
  // useEffect(() => {
  //   if (nlpScoreError !== null) {
  //     console.log("NLP error: " + nlpScoreError);
  //   }
  // }, [nlpScoreError]);

  const ref = useRef();
  const renderTest = () => {
    return (
      <>
        <Test
          LO={state.LO}
          actID={state.actID}
          lang={state.lang}
          dir={state.dir}
          audKey={state.audioKey}
          anchorKey={state.anchorKey}
          mainAudio={state.mainAudio}
          questionText={state.questionText}
          question={state.question}
          onAnswerSelected={handleClick}
          onAnswerSelectedMod={handleClickMod}
          onTrigger={ref.trigger}
          navLen={props.navLen}
          navCrrtItem={props.navCrrtItem}
          onClickNextBtn={props.clickNextBtn}
          onSubmitBtn={props.submitBtn}
          onEvaluation={handleEval}
          // ** Issue #163 - disable NLP calls until post-launch period
          //onLoseFocusCheckNLP={checkNLP}
          invalidEnInput={showNonENTextError}
        />
        <Footer actID={state.actID} mod={state.modality} template={state.template} skill={state.skill} />
      </>
    );
  };

  return <div>{renderTest()}</div>;
};

export default LCM;
